import { AxiosError } from 'axios'
import Vue from 'vue'
import { AMSAccountInfo } from '@/api/types'

const http = Vue.prototype.$http

export const LeavingMSNAPI = {
  async leavingmsn(redirectUrl: string, institutionCode: string): Promise<void> {
    const data = await http.post('/page/ams/leavingmsn', {
      redirectUrl,
      institutionCode,
    })
  },
}
